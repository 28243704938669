<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
                <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

                </v-breadcrumbs>
            </div>
        </v-row><br><br><br />

        <v-card>
            <v-card-title><v-row class="pa-2" justify="center" style="color: white; background-color: gray;">View
                    Receipt</v-row></v-card-title>
            <v-card-text><br>
                <v-row justify="center">
                    <v-col cols="12" sm="3" md="3">
                        <v-text-field label="Receipt No" outlined v-model="selected_receipt_no"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="1" md="1">
                        <v-btn class="primary" @click="fetchreceipt">View</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <br />
       
        <receipt_main :key="receipt_main_key" :recno="selected_receipt_no" 
        ></receipt_main>






    </div>
</template>
<script>
import axios from "axios";
import receipt_main from "./receipt_main.vue";
export default {
    components: {
        receipt_main,
    },
    data() {
        return {
            receipt_main_key:0,
            details: "",
            selected_receipt_no: "",
            color: "",
            snackbar: false,
            snackbar_msg: "",
            items: [
                {
                    text: 'Dashboard',
                    disabled: false,
                    href: '/home',
                },
                {
                    text: localStorage.getItem("jhhjbyj"),
                    disabled: false,
                    href: '/sublink',
                },
                {
                    text: 'View Receipt',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
            ],
        };
    },
    mounted() {
        // this.getfine("119B1128");
        this.init();
    },
    methods: {
        fetchreceipt() {
            var params = { "receipt_no": this.selected_receipt_no };
            this.init_loading = true;
            axios
                .post("/Librarian/fetch_book_receipt", params)
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.receipt_main_key++;
                        this.details = res.data.details[0];
                    }
                    else {
                        this.showSnackbar("#b71c1c", res.data.msg);
                    }
                })

        },
        printElem(divId) {
            var content = document.getElementById(divId).innerHTML;
            var mywindow = document.body.innerHTML;

            document.body.innerHTML = content;
            window.print();
            document.body.innerHTML = mywindow;
            location.reload();
            return true;
        },
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
    },
};
</script>
<style>
table,
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
}
</style>
