<template>
    <div>
      

        <v-row justify="center">
            <v-btn v-if="details" @click="printElem('exceltable1')" color="success" dark class=" ma-4">
                Print
            </v-btn>
        </v-row>
        <v-row justify="center" v-if="details">

            <br />
            <div id="exceltable1">
                <table style="font-size:20px; margin-left: 5%; margin-right: 5%;" width="90%">
                    <tbody>
                        <tr>
                            <td style="font-size: 24px;" colspan="3">

                                <center><b>Libray Fine receipt</b></center>

                            </td>
                        </tr>
                        <tr>
                            <td style="width: 25%;">
                                <span>
                                    <center> <v-img cover style="width: 80px; height: 80px;"
                                            :src="details.trust_logo_url"></v-img>
                                    </center>
                                </span>
                            </td>
                            <td style="width: 50%;">
                                <span>
                                    <center>

                                        <b> {{ details.trust_name }}<br />
                                            {{ details.org_name }}</b><br />

                                        {{ details.address }}<br />
                                        Phone : {{ details.contact }}<br />
                                        Email : {{ details.email }}<br />
                                        Website : {{ details.website }}<br />


                                    </center>
                                </span>
                            </td>
                            <td style="width: 25%;">
                                <span>
                                    <center> <v-img style="width: 80px; height: 80px;" cover
                                            :src="details.org_logo_url"></v-img>
                                    </center>
                                </span>
                            </td>
                        </tr>

                        <tr>
                            <td style="width: 25%;">
                                <span>
                                    <center> Receipt No:- <b>{{ details.receipt }}</b> </center>
                                </span>
                            </td>
                            <td style="width: 50%;">
                                <span>
                                    <center>
                                        Name:- <b>{{ details.name }}</b>
                                    </center>
                                </span>
                            </td>
                            <td style="width: 25%;">
                                <span>
                                    <center>date:-<b>{{ details.date }}</b></center>
                                </span>
                            </td>
                        </tr>
                        <tr v-if="details.division">
                            <td style="width: 25%;">
                                <span>
                                    <center> Program:- <b>{{ details.program }}</b> </center>
                                </span>
                            </td>
                            <td style="width: 50%;">
                                <span>
                                    <center>
                                        Division:- <b>{{ details.division }}</b>
                                    </center>
                                </span>
                            </td>
                            <td style="width: 25%;">
                                <span>
                                    <center>Year/class:- <b>{{ details.year }}</b></center>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <span>
                                    <center> <b>Paticulars of Fee</b> </center>
                                </span>
                            </td>
                            <td>
                                <span>
                                    <center>
                                        <b>Fee Amount (Rs.)</b>
                                    </center>
                                </span>
                            </td>

                        </tr>
                        <tr>
                            <td colspan="2">
                                <span>
                                    <center> Library book Fine </center>
                                </span>
                            </td>
                            <td>
                                <span>
                                    <center>
                                        {{ details.fine }}
                                    </center>
                                </span>
                            </td>

                        </tr>
                        <tr>
                            <td colspan="3">
                                <br />
                                <br />
                                <span style="margin: 10px; float: right; font-size: 15px;"> Authorized Signature</span>
                            </td>


                        </tr>
                    </tbody>
                </table>
            </div>


        </v-row>
        <br />
        <br />
        <br />
        <br />
        






    </div>
</template>
<script>
import axios from "axios";
export default {
    props: {
        recno: {
        required: true
      },
     
    },
    data() {
        return {
            details: "",
            selected_receipt_no: "",
            color: "",
            snackbar: false,
            snackbar_msg: "",
            items: [
                {
                    text: 'Dashboard',
                    disabled: false,
                    href: '/home',
                },
                {
                    text: localStorage.getItem("jhhjbyj"),
                    disabled: false,
                    href: '/sublink',
                },
                {
                    text: 'View Receipt',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
            ],
        };
    },
    mounted() {
        // this.getfine("119B1128");
        this.init();
    },
    methods: {
        init() {
            var params = { "receipt_no": this.recno };
            this.init_loading = true;
            axios
                .post("/Librarian/fetch_book_receipt", params)
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.details = res.data.details[0];
                    }
                    else {
                        this.showSnackbar("#b71c1c", res.data.msg);
                    }
                })

        },
        printElem(divId) {
            var content = document.getElementById(divId).innerHTML;
            var mywindow = document.body.innerHTML;

            document.body.innerHTML = content;
            window.print();
            document.body.innerHTML = mywindow;
            location.reload();
            return true;
        },
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
    },
};
</script>
<style>
table,
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
}
</style>
